import { ButtonGroup } from '../ButtonGroup';
import headerleoimage from '../../static/img/mask-group-2.png';
import { ButtonGroup } from "../ButtonGroup";
import * as styles from "./styles.module.scss";
import { Link } from 'react-router';

export const Header = () => {
	return (
		<div className={styles.header}>
			<Link to={'/'} className={styles.logoSection} title='Fibonacci'>
				<img
					className={styles.logoImg}
					alt="Fibonacci"
					src={headerleoimage}
				/>
				<div className={styles.logoText}>Fibonacci</div>
			</Link>

			<ButtonGroup
				align="justify"
				buttonLabel="Get a Demo"
				buttonStart={false}
				buttonEndHref="https://calendly.com/connor-fibonaccipartner/product-demo"
				buttonEndTarget="_blank"
				buttonVariantPrimaryClassName="header-button"
				className="header-button-group"
			/>
		</div>
	);
};