.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 50px 16px;
  gap: 16px;

}

.footerNav{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  > li{
    position: relative;
    &:not(:last-child){
      &:after{
        content: '';
        width: 2px;
        height: 16px;
        background: #9d9d9d;
        position: absolute;
        top: 5px;
        right: -16px;
      }
    }
    > a{
      color: #9d9d9d;
      font-family: Poppins, Helvetica;
    }
  }
}

.copyRight {
  color: #9d9d9d;
  font-family: Poppins, Helvetica;
}
