.BrandCarousel {
	align-items: center;
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 24px;
	justify-content: center;
	overflow: hidden;
	padding: 0 24px;
	position: relative;
	max-width: 100%;
	width: 100%;
	opacity: 1;
	@media (min-width: 768) {
		flex-direction: row;
		height: min-content;
		display: flex;
	}

	&Title{
		cursor: pointer;
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
		color: #fff;
		text-align: center;
		width: 100%;
		@media (min-width: 768) {
			width: 97px;
		}
	}
}