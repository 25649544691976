import React from "react";

const TermsUsePage = () => {
  return (
    <div className="content-page">
      <h1>Terms of Services</h1>
      <p>This contract for services ("Contract") is made and entered into as of the Effective Date, between Fibonacci Inc, a California corporation, 214 Forrester Rd, Los Gatos, CA 95032, and the said client, a corporation doing business in the state of their registered address.</p>

      <h2>Background</h2>
      <ol className="upper-alpha">
        <li>Corporation has developed an AI model (currently in its beta stage) (the "AI Model") which allows companies to input various marketing materials to predict the success of an advertisement campaign and predict return on advertisement spending; and </li>
        <li>Client, in an attempt to increase the success of marketing and advertisement campaigns and predict return on advertising spend, desires to engage Corporation under the terms and conditions set forth herein;</li>
      </ol>

      <p>NOW, THEREFORE, in consideration of the mutual covenants and agreements contained herein, the Parties agree as follows:</p>
      <ol className="ordered">
        <li>
          <strong>Scope of Service and Retention and Use of Data</strong>
          <ol className="ordered">
            <li>During the term of this Contract (as set out below), Client may access and use Corporation’s AI Model in the form of a software service (the “SaaS”). Corporation retains all right, title, and interest in and to the SaaS and AI Model, including, without limitation, all software included in and used to provide the SaaS and all logos and trademarks reproduced through the SaaS. This Contract does not grant Client (i) any right to reproduce, modify, distribute, or publicly display or perform the software included in the SaaS or AI Model, or (ii) any other right to the SaaS or AI Model not specifically set forth herein. </li>
            <li>Corporation shall be available between 9:00am PST and 5pm PST, or as reasonably requested by Client, to address any technical issues which hinder the Client’s ability to use the SaaS.</li>
            <li>The scope of the SaaS is confined to the obligations which are expressly enumerated herein. Any modifications or additions to the scope of the SaaS must be agreed upon in writing by the Parties.</li>
            <li>Corporation has the right to retain data that Client uploads to Corporation’s AI Model in use of the SaaS. This data shall be used solely for the purpose of training and evaluating the Corporation’s AI Model.</li>
          </ol>
        </li>
        <li>
          <strong>Client Input of Personal Information and Client Anonymization of Data</strong>
          <ol className="ordered">
            <li>The Client shall provide the Corporation with the detailed sales and marketing data following the use by the Client of the SaaS necessary to evaluate the quality and performance of the SaaS and AI Model, as well as further train the AI Model. Such information shall be anonymized by the Client (or some other third party), prior to receipt by the Corporation, in such a way that no individual can be identified from the information.</li>
          </ol>
        </li>
        <li>
          <strong>Term</strong>
          <ol className="ordered">
            <li>The term of this Contract shall commence on the Effective Date and shall continue until terminated as provided herein.</li>
          </ol>
        </li>
        <li>
          <strong>Compensation</strong>
          <ol className="ordered space-lg">
            <li>Client agrees to pay Corporation the sum of agreed amount per month for the SaaS. Payments shall be due on the 1st of the month following the Effective Date.</li>
            <li>Payments shall be made according to the following schedule:<div>100% of monthly amount due the 1st of every month.</div></li>
            <li>Corporation shall be entitled to restrict access to SaaS at any time following non-payment of the amount due in a particular month.</li>
          </ol>
        </li>
        <li><strong>Confidentiality and Restrictions on Use</strong>
          <ol className="ordered space-lg">
            <li>For the purposes of this section, <strong>“Confidential Information”</strong> means any and all information that is disclosed or otherwise furnished by or on behalf of Corporation to Client, regardless of its form or medium, whether conveyed orally, visually, electronically or in writing, and whether or not it is designated as “confidential” prior to or following the Effective Date, and that in any way relates to Corporation or its business or technology (including, without limitation, the AI Model), and includes, without limitation, any financial, business, marketing, technical and scientific information, unpatented inventions (whether patentable or not), trade secrets, ideas, methods and discoveries, know-how, software programs, unpublished patent products, improvements, and all intellectual property, manufacturing techniques, business opportunities and initiatives, code, interface elements, and other information that would reasonably be considered non-public, confidential or proprietary given the nature of the information and Corporation’s business.</li>
            <li>Notwithstanding Section 5.1, Confidential Information shall not include information that Client or Corporation can demonstrate by means of documentary evidence: (i) is or becomes publicly available other than as a result of a breach of this Contract; (ii) is or becomes available to Client or Corporation from a source (other than Client or Corporation) lawfully having possession of such information and the right to disclose it; or (iii) was already known to or in the possession of Client prior to being disclosed or otherwise furnished by or on behalf of Corporation pursuant to this Contract.</li>
            <li>Client shall not use Confidential Information for any purpose other than to facilitate the SaaS. Client (i) shall not disclose Confidential Information to any employee or contractor of Client unless such person needs access in order to facilitate the SaaS and is subject to a written agreement with Client with confidentiality and non-use terms no less restrictive than contained herein, and (ii) shall not disclose Confidential Information to any other third party without Corporation’s prior written consent. Without limiting the generality of the foregoing, Client shall protect Confidential Information with the same degree of care it uses to protect its own confidential information of similar nature and importance, but with no less than reasonable care. Client shall promptly notify Corporation of any misuse or misappropriation of Confidential Information that comes to Client’s attention. Notwithstanding the foregoing, Client may disclose Confidential Information to the extent required by applicable law or by proper legal or governmental authority. Client shall give Corporation prompt notice of any such legal or governmental demand and reasonably cooperate with Corporation in any effort to seek a protective order or otherwise to contest, limit, or protect such required disclosure, and at Corporation’s expense.</li>
          </ol>
        </li>
        <li><strong>Warranties</strong>
          <ol className="ordered space-lg">
            <li>Except as expressly stated herein, Corporation makes no warranties, express or implied, including, without limitation, any implied warranties of merchantability or fitness for a particular purpose.</li>
            <li>Client acknowledges that the SaaS and AI Model are in their “beta stage” of testing.</li>
            <li>Nothing in this Contract shall obligate Corporation to make more than commercially reasonable efforts to provide the SaaS, including, without limitation, the development and refinement of the AI Model.</li>
          </ol>
        </li>
        <li><strong>Indemnification</strong>
          <ol className="ordered space-lg">
            <li>Client shall indemnify and hold harmless Corporation, its officers, employees, contractors, and agents from any and all claims, damages, liabilities, and expenses arising out of or in connection with the SaaS provided under this Contract, including, without limitation, any third-party claims which may arise as the result of Client’s failure to satisfy the obligations under Section 2.1 of this Contract, except to the extent caused by the gross negligence or willful misconduct of Corporation.</li>
          </ol>
        </li>
        <li><strong>Termination</strong>
          <ol className="ordered space-lg">
            <li>Subject to Section 15.1, either party may terminate this Contract at any time, provided there are not less than 14 days' written notice to the other party.</li>
            <li>In the event of termination, Corporation shall be entitled to payment for any and all services performed up to the date of termination.</li>
          </ol> </li>
        <li><strong>Dispute Resolution</strong>
          <ol className="ordered space-lg">
            <li>Any disputes arising out of or relating to this Contract shall be resolved through mediation conducted by a mediator mutually agreed upon by the Parties.</li>
            <li>If mediation fails to resolve the dispute, the Parties agree to submit the dispute to binding arbitration in accordance with the rules of the American Arbitration Association, with the arbitration to be held in Santa Clara County, California.</li>
          </ol>
        </li>
        <li>
          <strong>Governing Law</strong>
          <ol className="ordered space-lg">
            <li>This Contract shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of laws principles.</li>
          </ol>
        </li>
        <li><strong>Entire Agreement</strong>
          <ol className="ordered space-lg">
            <li>This Contract constitutes the entire agreement between the Parties with respect to the subject matter and supersedes all prior agreements, understandings, and communications, whether written or oral.
            </li>
          </ol>
        </li>
        <li><strong>Amendments</strong>
          <ol className="ordered space-lg">
            <li>This Contract may not be amended except in a written agreement executed by authorized representatives of the Parties.</li>
          </ol>
        </li>
        <li><strong>Severability</strong>
          <ol className="ordered space-lg">
            <li>If any provision of this Contract is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</li>
          </ol>
        </li>
        <li><strong>Notices</strong>
          <ol className="ordered space-lg">
            <li>Any notices required or permitted under this Contract shall be in writing and shall be deemed delivered when delivered in person or three (3) days after being mailed by certified or registered mail, return receipt requested, to the addresses set forth above.</li>
          </ol>
        </li>
        <li><strong>Survivability</strong>
          <ol className="ordered space-lg">
            <li>It is the agreement of the Parties that the confidentiality provisions contained herein shall continue to bind the Parties regardless of any expiration of term, breach of contract, or any other event that shall bring about the end of this Contract or any event that would render this Contract void ab initio.</li>
          </ol>
        </li>
        <li><strong>Force Majeure </strong>
          <ol className="ordered space-lg">
            <li>No delay, failure, or default, other than a failure to pay fees when due, will constitute a breach of this Contract to the extent caused by epidemics, acts of war, terrorism, hurricanes, earth-quakes, other acts of God or of nature, strikes or other labor disputes, riots or other acts of civil disorder, embargoes, government orders responding to any of the foregoing, legislative or executive actions, or other causes beyond the performing party’s reasonable control.</li>
          </ol>
        </li>
        <li><strong>No Waiver</strong>
          <ol className="ordered space-lg">
            <li>Neither party will be deemed to have waived any of its rights under this Contract by lapse of time or by any statement other than by an authorized representative in an explicit written waiver. No waiver of a breach of this Contract shall constitute a waiver of any other breach of this Contract.</li>
          </ol>
        </li>
        <li><strong>Execution in Counterparts</strong>
          <ol className="ordered space-lg">
            <li>This Contract may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.</li>
          </ol>
        </li>


      </ol>

      <p style={{ textAlign: "center", fontStyle: 'italic', marginTop: '50px' }}>IN WITNESS WHEREOF, the Parties have executed this Contract as of the Effective Date.</p>
      <p style={{ textAlign: "center", fontStyle: 'italic', marginTop: '50px' }}>-rest of page left blank intentionally-</p>
    </div>
  );
};

export default TermsUsePage;