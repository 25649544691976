import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './styles.module.scss';

const ImageCarousel = ({images = []}) => {
  const settings = {
    infinite: true, // Loop the carousel
    speed: 3000, // Animation speed
    autoplay: true, // Enable autoplay
    autoplaySpeed: 0, // Seamless transition
    cssEase: "linear", // Smooth animation
    slidesToShow: 3, // Number of visible slides
    slidesToScroll: 1,
    arrows: false, // Hide navigation arrows
    pauseOnHover: false, // Keep autoplay running when hovering
    className: 'SlideItem',
    responsive: [
    {
      breakpoint: 480, // Screen width at which settings will apply
      settings: {
        slidesToShow: 1, // Number of slides to show on screens <= 480px
      }
    }
  ]
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Slider {...settings}>
        {images.map(({name, src}) => (
          <div key={name}>
            <img src={src} key={name}  alt={name} style={{ width: "200px" }} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;