import React from "react";

import { Outlet } from "react-router";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

export const LayoutContent = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div className="main-section">
          <div className="main-section-content">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
