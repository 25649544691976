import React from "react";
import * as images from '../../static/brands/*.png';
import ImageCarousel from "../ImageCarousel";
import styles from './styles.module.scss';

const BrandCarousel = () => {
	const imageList = Object.entries(images).map(([name, src]) => ({ name, src }))

	return (
		<div className={styles.BrandCarousel}>
			<div className={styles.BrandCarouselTitle}>
				<p>Brands love Leonardo:</p>
			</div>
			<ImageCarousel images={imageList} />
		</div>
	);
};

export default BrandCarousel;