const _temp0 = require("../../static/brands/jili-w.png");
const _temp1 = require("../../static/brands/pacarana-w.png");
const _temp2 = require("../../static/brands/pcgrowth.png");
const _temp3 = require("../../static/brands/playper.png");
const _temp4 = require("../../static/brands/powkid.png");
const _temp5 = require("../../static/brands/youchews.png");
module.exports = {
  "jili-w": _temp0,
  "pacarana-w": _temp1,
  "pcgrowth": _temp2,
  "playper": _temp3,
  "powkid": _temp4,
  "youchews": _temp5
}