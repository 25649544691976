import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router';
import { Layout } from './layout/Layout';
import { HomePage } from './screens/Home';
import TermsUsePage from './screens/TermsUse';
import PrivacyPolicyPage from './screens/PrivacyPolicy';
import { LayoutContent } from './layout/LayoutContent';

const App = () => {
	return (
		<Router>
			<Routes>
				<Route element={<Layout />}>
					<Route path="/" element={<HomePage />} />
				</Route>
				<Route element={<LayoutContent />}>
					<Route path="privacy-policy" element={<PrivacyPolicyPage />} />
					<Route path="terms-use" element={<TermsUsePage />} />
				</Route>
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		</Router>
	);
};

export default App;