import { Link } from 'react-router';
import * as styles from "./styles.module.scss";

export const Footer = () => {
	return (
		<div className={styles.footer}>
			<div className={styles.copyRight}>
				Copyright &copy; 2024-2025 FIBONACCI, INC. All rights reserved.
			</div>

			<ul className={styles.footerNav}>
				<li>
					<Link to='/privacy-policy' title='Privacy policy'>Privacy policy</Link>
				</li>
				<li>
					<Link to='/terms-use' title='Terms of Use'>Terms of Use</Link>
				</li>
			</ul>
		</div>
	);
};